import { HomeCarousel } from '@/components'
import Categories from '@/components/Categories'
import { Button } from 'antd'
import Image from 'next/image';
import Head from 'next/head'
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { BrandJsonLd, LocalBusinessJsonLd, LogoJsonLd, NextSeo } from 'next-seo';
import { pageSeo } from '@/utils/seoContent';


export default function Home() {
  const router = useRouter();
  return (
    <>
      <Head>
        <title>Chethi&apos;s</title>
      </Head>
      <NextSeo {...pageSeo.home} />
      <LogoJsonLd {...pageSeo.logoJsonLD} />
      <LocalBusinessJsonLd {...pageSeo.localBusinessJsonLD} />
      <BrandJsonLd {...pageSeo.brandJsonLD} />
      <div className='ch-home'>
        <div className='ch-home-section separated'>
          <HomeCarousel />
        </div>
        <div className="ch-home-section separated">
          <p className="ch-home-section-title">Categories</p>
          <Categories />
        </div>
        <div className="ch-home-section separated">
          <div className="ch-home-shop-now">
            <Image loading='eager' fill src='/assets/images/beigeLuxury1.webp' alt='Shop Now' />
            <div className='ch-home-shop-now-overlay'>
              <motion.div
              initial={{ opacity: 0, y: -40}}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 1.5 }}
              className='ch-home-shop-now-overlay-container'>
                <p className='ch-home-shop-now-overlay-text'>Do you want to live in your fashion dream?</p>
                <div className='ch-home-shop-now-overlay-action'>
                  <Button 
                    className='ch-btn'
                    type='primary'
                    onClick={()  => {router.push('/shop')}}
                  >
                      Shop Now
                  </Button>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="ch-home-section">
          <p className="ch-home-section-title">Our Goal</p>
          <div className='ch-home-goal'>
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 2, ease: 'easeInOut' }}
            >
              Our goal is make sure you will get the best high quality eco friendly products from our shop for affordable prices.
              Also we do our best for deliver your products to you doorstep as fast we can.
              That&apos;s a promise from <strong>Chethi&apos;s</strong>.
            </motion.p>
          </div>
        </div>
      </div>
    </>
  )
}
