import Image from 'next/image';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';

const categories = [
    {
        image: '/assets/images/bag.webp',
        title: 'Bags',
        path: '/shop/eco-bag'
    },
    {
        image: '/assets/images/cushion.webp',
        title: 'Cushion Covers',
        path: '/shop/cushion-covers'
    },
    {
        image: '/assets/images/frock.webp',
        title: 'Frocks',
        path: '/shop/frocks'
    }
];

const Categories = () => {
    const router = useRouter();

    return (
        <div className="ch-category-list">
            {
                categories.map(category => (
                <motion.div
                    key={category.title}
                    initial={{ scale: 0.5 }} 
                    whileInView={{ scale: 1 }}
                    transition={{ type: 'spring', duration: 1.5}}
                    whileHover={{ scale: 1.1 }}
                    className='ch-category-card'
                    onClick={() => {router.push(category.path)}}
                >
                    <Image 
                        className='ch-category-card-img'
                        src={category.image}
                        alt={category.title}
                        fill
                        sizes='100%'
                    />
                    <div className='ch-category-card-title'>{category.title}</div>
                </motion.div>
                ))
            }
        </div>
    )
}

export default Categories;