export const pageSeo =  {
    home: {
        title: 'Chethi\'s',
        description: 'Chethi\'s is an online eco-friendly fashion store where customers can create their own unique style.',
        canonical: 'https://chethis.lk',
        openGraph: {
            type: 'website',
            url: 'https://chethis.lk',
            title: 'Chethi\'s',
            siteName: 'Chethi\'s',
            description: 'Chethi\'s is an online eco-friendly fashion store where customers can create their own unique style.',
            images: [
                {
                    url: 'https://chethis.lk/assets/images/chethisLogoSquare.jpeg',
                    alt: 'Chethi\'s',
                },
                {
                    url: 'https://chethis.lk/assets/images/totebag.jpeg',
                    alt: 'Chethi\'s Tote Bag',
                },
                {
                    url: 'https://chethis.lk/assets/images/pouch.jpeg',
                    alt: 'Chethi\'s Pouch',
                },
                {
                    url: 'https://chethis.lk/assets/images/cushion.webp',
                    alt: 'Chethi\'s Cushion Cover',
                }
            ]
        }
    },
    shop: {
        title: 'Shop',
        canonical: 'https://chethis.lk/shop',
        description: 'Chethi\'s is an online eco-friendly fashion store where customers can create their own unique style.'
    },
    contact: {
        title: 'Contact Us',
        canonical: 'https://chethis.lk/contact-us',
    },
    about: {
        title: 'About Us',
        canonical: 'https://chethis.lk/about-us',
    },
    logoJsonLD: {
        logo: 'https://chethis.lk/assets/images/chethisLogoSquare.jpeg',
        url: 'https://chethis.lk'
    },
    brandJsonLD: {
        slogan: 'Eco friendly fashion solution',
        id: 'https://chethis.lk',
        logo: 'https://chethis.lk/assets/images/chethisLogoSquare.jpeg'
    },
    localBusinessJsonLD: {
        type: "Store",
        id: "http://chethis.lk",
        name: "Chethi's",
        description: "'Chethi\'s is an online eco-friendly fashion store where customers can create their own unique style.",
        url: "https://maps.app.goo.gl/2GhgdbABLpVHNEaV7",
        telephone: "+94776411718",
        address: {
            streetAddress: '457/2, Sri Saranankara Mawatha, Karawwa',
            addressLocality: 'Pitigala',
            addressRegion: 'Galle',
            postalCode: '80420',
            addressCountry: 'LK',
        },
        geo: {
            latitude: '6.3957405140804795',
            longitude: '80.24271888047419',
        },
        images: [
            'https://chethis.lk/assets/images/chethisLogoSquare.jpeg',
            'https://chethis.lk/assets/images/totebag.jpeg',
            'https://chethis.lk/assets/images/cushion.webp',
        ],
        sameAs: [
            'www.chethis.lk',
        ],
        openingHours: [
            {
            opens: '09:00',
            closes: '17:59',
            dayOfWeek: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ],
            validFrom: '2024-07-01',
            validThrough: '2050-07-01',
            }
        ],
        rating: {
            ratingValue: '5.0',
            ratingCount: '5',
        }
    }
}